<app-loader [isActive]="isLoading.value$ | async" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div class="flex flex-row items-center justify-start gap-2">
    <div>{{ 'comego.bulk-update' | translate }}</div>
    <div class="flex-auto"></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="this.group"
    class="mb-8 flex flex-col space-y-4"
    *ngIf="this.group as grp"
  >
    <div class="flex flex-row gap-2">
      <div class="flex flex-auto flex-col items-stretch justify-start gap-2 lg:wflex-[1_0_440px]">
        <ng-container *ngIf="{ shown: isFieldShown('user') } as ctrl">
          <div
            class="flex w-full flex-col"
            [ngClass]="{ 'cursor-pointer': group.controls.user.enabled }"
            (click)="$event.preventDefault(); group.controls.user.enabled && openUserPicker()"
            *ngIf="ctrl.shown && (workspace$canManageOthers.value$ | async)"
          >
            <mat-form-field class="form-control-interactive">
              <mat-label>{{ 'user.select' | translate }}</mat-label>
              <div *ngIf="groupValue.user as user" class="-mb-16px flex items-center space-x-1.5">
                <app-user-avatar size="40px" class="rounded-full" [userId]="user.id"></app-user-avatar>
                <div class="flex flex-auto flex-col leading-none">
                  <span>{{ user.name }}</span>
                  <span class="mat-hint text-sm">{{ user.email }}</span>
                </div>
                <button
                  *ngIf="postDiffUser$ | async"
                  mat-icon-button
                  (click)="$event.preventDefault(); $event.stopPropagation(); patchValue({ user: null })"
                >
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
              <input
                matInput
                [value]="groupValue.user"
                readonly
                [disabled]="group.controls.user.disabled"
                class="!pointer-events-none"
              />
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="{ shown: isFieldShown('date') } as ctrl">
          <div
            class="flex w-full flex-col"
            [ngClass]="{ 'cursor-pointer': group.controls.date.enabled }"
            (click)="$event.preventDefault(); group.controls.date.enabled && openCalPicker()"
            *ngIf="ctrl.shown"
          >
            <mat-form-field class="pointer-events-none">
              <mat-label>{{ 'timer.date' | translate }}</mat-label>
              <input
                matInput
                [value]="groupValue.date | formatDate"
                [disabled]="group.controls.date.disabled"
                class="!pointer-events-none"
              />
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container
          *ngIf="{
            error: getErrorObservable('name') | async,
            shown: isFieldShown('name'),
          } as ctrl"
        >
          <mat-form-field *ngIf="ctrl.shown">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea type="text" matInput formControlName="name" cdkInitialFocus cols="3"></textarea>
            <mat-error *ngIf="ctrl.error as error">{{
              error.content | translate: (error.args | translateArgs)
            }}</mat-error>
            <mat-hint class="w-full text-right"> {{ groupValue.name?.length ?? 0 }} / {{ maxLength }} </mat-hint>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <div class="mat-bd-5 -mx-4 mb-8 flex flex-col space-y-4 rounded-lg px-4 pb-4 pt-3">
      <div class="mb-4 flex">
        <div class="flex flex-grow flex-col">
          <div class="flex flex-col">
            <ng-container *ngIf="duration$ | async as dur">
              <div class="flex flex-col space-y-0.5">
                <span class="text-muted mat-typography"
                  >{{ 'comego.types.work' | translate }}:
                  {{ dur.working + dur.absence | parseMsAsDuration: false }}
                </span>
                <span class="text-muted mat-typography"
                  >{{ 'comego.types.pause_short' | translate }}:
                  {{ dur.pause | parseMsAsDuration: false }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="flex-shrink-0">
          <ng-container *ngIf="currentDateHasSchedule$ | async">
            <button mat-stroked-button type="button" (click)="resetToSchedule()">
              {{ 'schedules.resetToUserSchedule' | translate }}
            </button>
          </ng-container>
        </div>
      </div>
      <div class="flex flex-col space-y-3" *ngIf="{ times: getErrorObservable('times') | async } as error">
        <ng-container
          *ngFor="let comegoGroup of group.controls.times.controls; let ctrlIndex = index; trackBy: ctrlIndex"
        >
          <div class="flex flex-col" ngClass.lt-md="mat-bg-5 rounded-lg px-4 pt-3 pb-4">
            <form
              ngClass.gt-sm="group flex space-x-2 flex-grow"
              ngClass.lt-md="group flex flex-col space-y-1 space-x-0"
              (submit)="$event.preventDefault()"
              [formGroup]="comegoGroup"
              (blur)="blurFormSort($event)"
            >
              <div class="flex flex-grow space-x-2" *ngIf="{ enabled: enabledTypes.value$ | async } as ctrl">
                <div
                  class="mt-4 flex size-6 flex-shrink-0 items-center justify-center rounded-full bg-white p-1 leading-6 shadow dark:bg-neutral-600 dark:shadow-none"
                  [ngClass]="{
                    'opacity-60': comegoGroup.controls.type.disabled,
                  }"
                  *ngIf="iconMap[comegoGroup.getRawValue()?.type] as type"
                >
                  <mat-icon inline class="text-center">{{ type }}</mat-icon>
                </div>
                <mat-form-field class="flex wflex-[1_0_140px]" ngClass.lt-md="flex-1">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type">
                    <mat-option [disabled]="!ctrl.enabled.work" value="work">{{
                      'comego.types.work' | translate
                    }}</mat-option>
                    <mat-option [disabled]="!ctrl.enabled.pause" value="pause">{{
                      'comego.types.pause' | translate
                    }}</mat-option>
                    <mat-option [disabled]="!ctrl.enabled.absence" value="absence">{{
                      'comego.types.absence' | translate
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex space-x-2">
                <div
                  ngClass.gt-sm="grid grid-cols-[90px_1fr_90px] items-center space-x-2"
                  ngClass.lt-md="grid grid-cols-[1fr_40px_1fr] items-center"
                >
                  <app-time-input
                    matInput
                    [inputPlaceholder]="'timer.time.start' | translate"
                    formControlName="start"
                    [displayFormat]="false"
                    #tStartFormField
                    [maxlength]="5"
                    elementRef
                    #startInpRef="nativeElement"
                    (focus)="this.selectInput($event, startInpRef)"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
                  >
                    <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
                      <mat-form-field fxFill>
                        <mat-label>{{ inputPlaceholder }}</mat-label>
                        <input
                          type="text"
                          matInput
                          [placeholder]="inputPlaceholder"
                          [formControl]="control"
                          (blur)="blurFormSort($event)"
                        />
                      </mat-form-field>
                    </ng-template>
                  </app-time-input>
                  <span class="tg-seperator mx-2 justify-self-center">
                    <mat-icon>arrow_forward</mat-icon>
                  </span>
                  @if (comegoGroup.getRawValue()?.recording) {
                    <ng-container *ngTemplateOutlet="recordingText"></ng-container>
                  } @else {
                    <app-time-input
                      matInput
                      [inputPlaceholder]="'timer.time.end' | translate"
                      formControlName="end"
                      [displayFormat]="false"
                      #tEndFormField
                      [maxlength]="5"
                      elementRef
                      #endInpRef="nativeElement"
                      (focus)="this.selectInput($event, endInpRef)"
                      (keydown.enter)="$event.preventDefault()"
                      (click)="$event.stopPropagation(); this.selectInput($event, endInpRef)"
                    >
                      <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
                        <mat-form-field fxFill>
                          <mat-label>{{ inputPlaceholder }}</mat-label>
                          <input
                            type="text"
                            matInput
                            [placeholder]="inputPlaceholder"
                            [formControl]="control"
                            (blur)="blurFormSort($event)"
                          />
                          <mat-hint *ngIf="calculateDuration(comegoGroup, ctrlIndex) as duration">
                            {{ duration | parseMsAsDuration: false }}
                          </mat-hint>
                        </mat-form-field>
                      </ng-template>
                    </app-time-input>
                  }
                </div>
                @if (!comegoGroup.getRawValue()?.recording) {
                  <div class="mt-2 flex space-x-2 opacity-40 group-hover:opacity-100">
                    <button
                      mat-icon-button
                      [disabled]="comegoGroup.disabled || (this.data?.time?.lockItem && ctrlIndex === 0)"
                      (click)="deleteItem(ctrlIndex)"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                } @else {
                  <div class="w-10"></div>
                }
              </div>
            </form>
            <div class="text-warn -mt-2 ml-8 text-sm" *ngIf="error.times?.index === ctrlIndex">
              {{ error.times.content | translate: (error.times.args || {} | translateArgs) }}
            </div>
          </div>
        </ng-container>
        <div class="flex justify-center">
          <button
            mat-icon-button
            color="primary"
            (click)="addTime()"
            [disabled]="group.disabled || !(canAddTime.value$ | async)"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
  <ng-container
    *ngIf="{
      times: getErrorObservable('times') | async,
      manual: error$.value$ | async,
    } as error"
  >
    @if (error.manual || error.times) {
      <div class="pb-16"></div>
      <div class="absolute inset-x-0 bottom-0 mx-3 mb-14">
        <div class="alert alert-danger alert-tight rounded py-2" *ngIf="error.manual as err">
          {{ err.message | translate: (err.args | translateArgs) }}
        </div>
        <ng-container *ngIf="!error.manual && error.times && error.times.index === undefined && error.times as br">
          <div class="alert alert-danger alert-tight rounded py-2">
            {{ br.content | translate: (br.args || {} | translateArgs) }}
          </div>
        </ng-container>
      </div>
    }
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled">
  <button
    mat-flat-button
    class="mat-action-filled"
    [color]="'primary'"
    (click)="submit()"
    [disabled]="group.invalid || (error$.value$ | async)"
  >
    {{ 'comego.bulk-update' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #recordingText>
  <div class="flex animate-pulse items-center space-x-1 text-red-500 flex-shrink-0">
    <div class="size-2 rounded-full bg-red-500 flex-shrink-0"></div>
    <div translate>time.recording</div>
  </div>
</ng-template>
